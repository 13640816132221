@import './outpost-variables.scss';

.react-datepicker-popper {
  z-index: 2000;

  @media screen and (max-width: $mobile) {
    width: 100%;
  }
}

.mobile-react-datepicker {
  border-radius: 4px;
  font-size: 16px;
  font-family: 'Inter';
  color: #18181d;
  position: relative;
  display: flex;
  border: 1px solid #c3c3c3;
  @media screen and (max-width: $mobile) {
    flex-direction: column;
    width: 87vw;
    margin-left: -25px;
    min-height: 50vh !important;
  }
}

.react-datepicker__month-container {
  @media screen and (max-width: $mobile) {
    min-height: 50vh !important;
  }
}

.datepicker-input {
  border: none !important;
  border-radius: 0.5rem !important;
  font-size: 14px !important;
  &:focus {
    border: none !important;
    box-shadow: none !important;
  }
}

.mobiscroll-inputx {
  border: none !important;
  font-size: 14px !important;
  &:focus {
    border: none !important;
    box-shadow: none !important;
  }
}

.react-datepicker {
  border-radius: 4px;
  font-size: 16px;
  font-family: 'Inter';
  color: #18181d;
  position: relative;
  display: flex;
  border: 1px solid #c3c3c3;

  @media screen and (max-width: $mobile) {
    flex-direction: column;
  }

  &-wrapper {
    width: 100%;
  }

  &__close-icon {
    padding-right: 34px;
  }

  &__input-container {
    width: 100%;

    > input {
      width: 100%;
      padding: 0 1rem;
      height: 2.5rem;
      border-radius: 0.75rem;
      border: 1px solid #cccccc;

      &:focus {
        // border: 1px solid #04064b;
        // box-shadow: 0 0 0 1px #04064b !important;
        outline: none;
      }
    }
  }

  &__close-icon::after {
    height: 30px;
    width: 30px;
    background-color: #1b3d71;
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
  }

  &__time-container {
    width: 100% !important;
  }

  &__time-box {
    width: 100% !important;
  }

  &__time-list {
    @media screen and (max-width: $mobile) {
      height: 60px !important;
    }
  }

  &__time-list-item {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media screen and (max-width: $mobile) {
      justify-content: center;
    }

    &--selected {
      background-color: $reactDatepickerSelectedBackgroundColor !important;
    }
  }

  &__triangle {
    border-bottom-color: #fff;
  }

  &__navigation {
    background: none;
    line-height: 2rem;
    text-align: center;
    cursor: pointer;
    border: 0.7rem solid transparent;
    position: absolute;
    top: 10px;
    z-index: 1;
    height: 20px;
    width: 20px;
    text-indent: -999em;
    overflow: hidden;
    &--previous {
      left: 8px;
      border-right-color: #c3c3c3;
    }

    &--next {
      right: 8px;
      border-left-color: #c3c3c3;
    }
  }

  &__day-name {
    width: 30px;
    display: inline-block;
    line-height: 30px;
    text-align: center;
    margin: 0.5rem;
    margin-bottom: 0;
    color: #57575c;
    font-weight: 500;
    font-size: 0.95rem;
  }

  &__month {
    background-color: #fff;
    margin-top: 0;
  }

  &__header {
    padding-top: 8px;
    text-align: center;
    background-color: #fff;
    border-bottom: #c3c3c3;
    position: relative;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &__navigation {
    &--years-upcoming {
      top: -8px;
      left: calc(50% - 10px);
      border-color: transparent transparent #c3c3c3;
    }

    &--years-previous {
      bottom: -2px;
      left: calc(50% - 10px);
      border-color: #c3c3c3 transparent transparent;
    }
  }

  &__current-month {
    display: none;
    text-align: center;
    line-height: 1.8rem;
    font-size: 1.2rem;
  }

  &__month-dropdown,
  &__year-dropdown {
    max-height: 300px;
    overflow-y: auto;
    padding: 4px 0;
    box-sizing: border-box;
    background-color: #fff;
  }

  &__month-option,
  &__year-option {
    position: relative;
    background-color: #fff;
    padding: 8px 12px;
    width: 100%;
    &:hover {
      background-color: #f4f6fa;
    }

    &--selected {
      display: none;
    }

    &--selected_month,
    &--selected_year {
      background-color: $reactDatepickerSelectedYearBackgroundColor;
      color: #fff;

      &:hover {
        background-color: $reactDatepickerSelectedYearBackgroundColor;
      }
    }
  }

  &__month-read-view,
  &__year-read-view {
    visibility: visible !important;
    &--selected-month,
    &--selected-year {
      font-size: 1.2rem;
      line-height: 1.8rem;
      font-weight: 500;
    }

    &--down-arrow {
      top: 13px;
    }
  }

  &__month-container {
    @media screen and (max-width: $mobile) {
      overflow-x: scroll;
    }
  }

  &__month {
    text-align: center;
    margin: 0.5rem;
    margin-top: 0;
  }

  &__week {
    white-space: nowrap;
  }

  &__day {
    cursor: pointer;
    width: 30px;
    display: inline-block;
    line-height: 35px;
    text-align: center;
    margin: 0.5rem;
    height: 35px;
    border-radius: 0.3rem;

    &:hover {
      background-color: $reactDatepickerDayHoverBackgroundColor;
      color: #fff;
    }

    &--outside-month {
      color: #cecece;
    }

    &--selected,
    &--keyboard-selected {
      background-color: $reactDatepickerKeyboardSelectedBackgroundColor;
      color: #fff;

      &:hover {
        background-color: $reactDatepickerKeyboardSelectedBackgroundColor;
      }
    }
  }
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 115px;

  @media screen and (max-width: $mobile) {
    display: none;
  }
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}
