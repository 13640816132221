//calendar-component
$rbcEventBackgroundColor: #32517f;
$rbcEventBorderColor: #6f92c6;
$rbcEventIsBookedBackgroundColor: #f5938c;
$rbcCurrentTimeIndicator: #5fd7b6;

//datepicker
$reactDatepickerSelectedBackgroundColor: #04064b;
$reactDatepickerSelectedYearBackgroundColor: #04064b;
$reactDatepickerDayHoverBackgroundColor: rgba(4, 6, 75, 0.6);
$reactDatepickerKeyboardSelectedBackgroundColor: #04064b;


//screens
$mobile: 480px;
