@import './outpost-variables.scss';

// .rbc-calendar {
//   margin-bottom: 1rem;
// }

.rbc-event {
  position: relative;
  background-color: $rbcEventBackgroundColor !important;
  padding: 1px 2px;
  // border: none !important;
  border: 1px solid $rbcEventBorderColor !important;
  border-radius: 2px;
  display: flex;
  justify-content: center;

  &:focus {
    outline: none !important;
  }

  &.rbc-selected {
    background-color: inherit;
  }
}

.rbc-month-view {
  // .rbc-month-header {
  //   border-bottom: 1px solid #ddd;
  // }

  .rbc-event {
    margin: 0 auto;
    width: 98%;
  }
}

// .rbc-toolbar-label {
//   font-size: 1.5rem;
//   color: #696969;
// }

.rbc-label.rbc-item-header-gutter {
  border: none;
}

.rbc-allday-cell {
  display: none;
}

.rbc-today {
  background-color: inherit;
}

.rbc-current-time-indicator {
  background-color: $rbcCurrentTimeIndicator;
  height: 2px;
}

.rbc-month-view {
  border-top: none;
}

.rbc-month-view {
  height: auto;
}

.rbc-time-header-content {
  border: none;
}

.rbc-event-label {
  display: flex;
  align-items: center;
  font-size: 11px;
  color: #04064b !important;
}

.rbc-btn-group-wrapper {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rbc-date-cell,
.rbc-day-bg {
  color: rgba(0, 0, 0, 0.8);
  &.rbc-off-range,
  &.rbc-off-range-bg {
    background-color: #fff;
    color: #696969;
  }
}

.rbc-date-cell.rbc-off-range {
  border-right: 1px solid #ddd;
}

.rbc-event {
  background-color: #e8f1fd !important;
  border-color: #e8f1fd !important;
  border-radius: 0.5rem;
}
